import React, { useState } from 'react'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import styleConfig from '@/styles/style-config'
import Icon from '../icon/icon'
import { removePx } from '@/lib/helpers'
import { Image } from '@/components/image'
import { trans } from '@/lib/locale-helper'

const ArticleSlider = ({ slidersCollection }) => {
  const [slider, setSlider] = useState(null)
  return (
    <>
      <div className="product-slider relative z-20 my-4">
        {slidersCollection != null && slidersCollection?.length > 5 && (
          <div className="product-slider__arrows absolute right-0 hidden lg:block">
            <button
              className="product-slider__arrow product-slider__arrow--prev"
              onClick={() => slider.slidePrev()}
              aria-label="previous"
            >
              <Icon
                className={`cursor-pointer`}
                viewBox={`0 0 36 28`}
                size={28}
                icon="slide-arrow-left"
              />
            </button>
            <button
              className="product-slider__arrow product-slider__arrow--next"
              onClick={() => slider.slideNext()}
              aria-label="next"
            >
              <Icon
                className={`cursor-pointer`}
                viewBox={`0 0 36 28`}
                size={28}
                icon="slide-arrow-right"
              />
            </button>
          </div>
        )}
        {slidersCollection?.length > 0 && (
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={10}
            onSwiper={(swiper) => {
              setSlider(swiper)
            }}
          >
            {slidersCollection?.length > 0 &&
              slidersCollection?.map((slider, index) => (
                <SwiperSlide
                  className="product-slider__slide "
                  key={index}
                  style={{ width: '305px', minWidth: '305px', marginRight: '10px' }}
                >
                  <Link href={slider?.slug ? slider?.slug : '/'}>
                    <div className="content-grid-item bg-gray-100">
                      <div className="content-grid-item__image bg-center bg-cover">
                        <div className="relative h-220">
                          <Image
                            src={slider?.image?.url}
                            alt="..."
                            layout="fill"
                            objectFit="cover"
                            objectPosition="center"
                          />
                        </div>
                      </div>
                      <div className="content-grid-item__content bg-gray-200 px-15r pt-15r pb-27.5 xl:px-4 xl:py-4 md:pt-5 relative flex flex-col">
                        <div className="flex flex-1 flex-col justify-between gap-2">
                          <h3 className="2xs:mb-13 md:mb-11 lg:mb-12 xl:mb-2.5 xl:leading-20 font-primary font-normal xl:text-22 uppercase xl:tracking-tight lg:leading-18 lg:text-md md:text-19 md:leading-17 2xs:leading-15 2xs:text-17 overflow-hidden">
                            {slider.title}
                          </h3>
                          <p className="flex-1 content-grid-item__excerpt lg:text-base lg:leading-20 leading-18 text-sm font-normal break-words overflow-hidden">
                            {slider.excerpt}
                          </p>
                        </div>
                        <span className="flex-1 content-action-link inline-flex mt-auto items-end group font-normal text-sm md:pt-0 md:text-base">
                          <div className="flex items-center">
                            <span className="py-1 border-b border-gwOrange text-spgs leading-15 text-black hover:text-gray-700 focus:text-gray-600">
                              {slider?.ctaCopy ? slider?.ctaCopy : trans('read-more', 'Read More')}
                            </span>
                            <svg
                              xmlns="http:www.w3.org/2000/svg"
                              width="10"
                              height="8"
                              viewBox="0 0 10 8"
                              fill="none"
                              className="ml-11.21"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.394 0.22505C5.63807 -0.0190273 6.0338 -0.0190272 6.27788 0.225051L9.61121 3.55838C9.85529 3.80246 9.85529 4.19819 9.61121 4.44227L6.27788 7.7756C6.0338 8.01968 5.63807 8.01968 5.394 7.7756C5.14992 7.53152 5.14992 7.13579 5.394 6.89172L7.66039 4.62533L0.835938 4.62532C0.490759 4.62532 0.210937 4.3455 0.210938 4.00032C0.210938 3.65515 0.49076 3.37532 0.835938 3.37532L7.66039 3.37533L5.394 1.10893C5.14992 0.864856 5.14992 0.469128 5.394 0.22505Z"
                                fill="#E36F22"
                              />
                            </svg>
                          </div>
                        </span>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </div>
      <style jsx>{`
        .product-slider__arrows {
          top: -49px;
        }
        .content-grid-item {
          height: 440px;
          max-height: 440px;
        }

        .content-grid-item h3 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .content-grid-item__excerpt {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        .content-grid-item__image {
          min-height: 200px;
        }
        .content-grid-item__content {
          height: calc(100% - 220px);
          min-height: 167px;
        }
        @media (min-width: ${styleConfig.theme.screens.md}) {
          .content-grid-item__content {
            min-height: 220px;
          }
        }
        @media (min-width: ${styleConfig.theme.screens.lg}) {
          .content-grid-item__content {
            min-height: 220px;
          }
        }
        @media (min-width: ${styleConfig.theme.screens.xl}) {
          .content-grid-item__content {
            min-height: 220px;
          }
        }
        @media (min-width: ${styleConfig.theme.screens.xl}) {
          .content-action-link {
            bottom: 9.5px;
            margin-left: 0px;
          }
        }
        @media (min-width: ${styleConfig.theme.screens.xxs}) {
          .content-action-link {
            bottom: 7.5px;
            margin-left: 1px;
          }
        }
      `}</style>
    </>
  )
}

export default ArticleSlider
