import styleConfig from '@/styles/style-config'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import ArticleSlider from './ArticleSlider'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { getSliderData } from '@/lib/modal'
import { getContentRecsData } from '@/lib/search-spring'
import { getCurrentRegion } from '@/lib/region-helper'

const ModuleSlider = (props) => {
  const [sliderData, setSliderData] = useState([])
  const numberOfCards = props?.numberOfCards ?? 8

  const getArticleBlogData = async (sliderId) => {
    const sliderCollection = props?.slidersCollection?.items
      ? props?.slidersCollection?.items?.slice(0, numberOfCards)
      : []

    if (props.blogCards?.length) {
      let data = await getSliderData(sliderId)
      const transformedData = data?.length
        ? data.map((item) => ({
            slug: item.url,
            image: {
              url: `https://${
                item?.images?.backgroundImageMobile?.file?.url ||
                item?.images?.backgroundImageTablet?.file?.url ||
                item?.images?.backgroundImageDesktop?.file?.url
              }`,
            },
            excerpt: item.excerpt,
            title: item.title,
          }))
        : []
      if (transformedData?.length) {
        setSliderData(transformedData?.slice(0, numberOfCards))
      } else {
        setSliderData(sliderCollection)
      }
    } else {
      setSliderData(sliderCollection)
    }
  }

  useEffect(() => {
    let region = getCurrentRegion()
    if (props?.sys?.id && props?.searchSpringProfileTag && props?.searchSpringProfileTag != '') {
      getContentRecsData(props.searchSpringProfileTag, numberOfCards).then((data) => {
        let articleArr = []
        if (data && data.length > 0) {
          data[0].results.forEach((blog) => {
            let blogData = blog?.attributes
            if (Object.keys(blogData).length > 0) {
              let blogPath = new URL(blogData.url)
              let href = blogPath?.href?.replace('domain', region.domain)
              let pathObj = href?.split(region.domain)
              let blogCard = {
                ctaCopy: 'Read More',
                title: blogData.title,
                entryTitle: blogData.entry_title,
                slug: pathObj[1],
                image: {
                  url: blogData.image,
                },
                excerpt: blogData.content,
              }
              articleArr.push(blogCard)
            }
          })
          setSliderData(articleArr)
        }
        if (articleArr.length == 0) {
          getArticleBlogData(props?.sys?.id)
        }
      })
    } else if (props?.sys?.id) {
      getArticleBlogData(props?.sys?.id)
    }
  }, [props?.sys?.id])

  return (
    <>
      <div className={`product-up container-xl my-10 md:my-16 xl:my-20`}>
        <div className={'text-black flex justify-between items-end trend-m-b'}>
          <div className="flex flex-col">
            {props.eyebrow && (
              <div className={`title text-sm font-bold uppercase text-gray-600 tracking-widest`}>
                {props.eyebrow}
              </div>
            )}
            {props.title && (
              <div>
                <h3 className="product-up__title font-primary uppercase font-normal">
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props.title}</ReactMarkdown>
                </h3>
              </div>
            )}
          </div>
          {props?.textLink && (
            <Link
              className={`border-b-2 border-primary font-bold uppercase text-xs lg:text-base leading-15 ${
                sliderData?.length > 4 ? 'ml-5 mr-0 lg:mr-172' : ''
              }`}
              href={props.textLink}
            >
              {props?.linkText}
            </Link>
          )}
        </div>
        {props?.sliderType === 'Content Card > Article' && (
          <ArticleSlider slidersCollection={sliderData} />
        )}
      </div>
      <style jsx>
        {`
          @media (min-width: ${styleConfig.theme.screens.md}) {
            .product-up {
              margin-top: 50px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.lg}) {
            .product-up {
              margin-top: 68.5px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .product-up {
              margin-top: 94.5px;
            }
          }

          .product-up {
            padding-left: 24px;
            padding-right: 24px;
          }

          .trend-m-b,
          .slider-m-b {
            margin-bottom: 20px;
          }

          .product-up__title {
            font-size: 20px;
            line-height: 18px;
            letter-spacing: -0.5px;
          }

          @media (min-width: ${styleConfig.theme.screens.md}) {
            .product-up__title {
              font-size: 22px;
              line-height: 20px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.lg}) {
            .product-up {
              padding-left: 15px;
              padding-right: 15px;
            }

            .product-up__title {
              font-size: 28px;
              line-height: 26px;
            }

            .text-28 {
              font-size: 28px;
            }

            .leading-26 {
              line-height: 26px;
            }

            .slider-m-b {
              margin-bottom: 28.5px;
            }
          }

          @media (min-width: ${styleConfig.theme.screens.xl}) {
            .product-up {
              padding-left: 59px;
              padding-right: 59px;
            }

            .product-up__title {
              font-size: 32px;
              line-height: 30px;
            }

            .text-32 {
              font-size: 32px;
            }

            .leading-30 {
              line-height: 30px;
            }

            .slider-m-b {
              margin-bottom: 34.5px;
            }
          }
        `}
      </style>
    </>
  )
}

export default ModuleSlider
